.backgroundLions{
    width: 100%;
    height: 15.438rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.438rem;

}

.lionsImg{
    width:16.305rem;
    height:15.438rem;
    object-fit: contain;
    opacity: 0.25;
    margin-top: 0.638rem;
}

.each-btn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20.5rem;
    height: 2.5rem;
    border-radius: 8px;
    background-color: #407cca;
    margin-bottom: 0.5rem;
}

.btn-text{
    font-family: NotoSansCJKkr;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}
.bottom{
    height: 4rem;
}

.purpose-number{
    font-family: NotoSansCJKkr;
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #00338d;
}

.purpose-yellow-line{
    width: 1rem;
    height: 0.125rem;
    margin: 0.125rem 17.5rem 0.125rem 0;
    background-color: #ebb700;
}

.purpose-yellow-line-long3{
    width: 2.7rem;
    height: 0.125rem;
    margin: 0.125rem 17.5rem 0.125rem 0;
    background-color: #ebb700;
}

.purpose-yellow-line-long4{
    width: 3.5rem;
    height: 0.125rem;
    margin: 0.125rem 17.5rem 0.125rem 0;
    background-color: #ebb700;
}

.purpose-text{
    margin: 0.125rem 0 0;
    font-family: NotoSansCJKkr;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #55565a; 
}

.song-img{
    margin-top: 1.5rem;
    width: 22.5rem;
    height: 26.016rem;
    object-fit: contain;
}

.slogan-img{
    width: 9rem;
    height: 12.375rem;   
}

.slogan-text1{
    font-family: NotoSansCJKkr;
    font-size: 1.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: center;
    color: #0d2240;
}

.slogan-text2{
  font-family: NotoSansCJKkr;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #55565a;
}

.slogan-first-row{
    display: flex;
    flex-direction: row;
    margin-top: 1.563rem;
}

.slogan-second-row{
    display: flex;
    flex-direction: row;
    margin-top: 2.438rem;
}

.init-text-wrapper{
    display: flex;
    flex-direction: column;
    margin-top: 1.188rem;
    align-items: center;
}

.init-text1{
    font-family: NotoSansCJKkr;
    font-size: 1.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: center;
    color: #0d2240;
}

.init-text2{
    font-family: NotoSansCJKkr;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #55565a;
}

.melvin-jones{
    font-family: NotoSansCJKkr;
    font-size: 1.875rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    margin-top:1.5rem;
    color:#ffffff;
}

.wrapper-init-back-logo{
    position:relative;
    width: 20.5rem;

}

.init-back-logo{
    position: absolute;
    top:5.8444rem;
   
    width: 20.5rem;
    height: 19.417rem;
    object-fit: contain;
    opacity: 0.22;
    z-index: 1;
}

.chairman-text1{
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #00338d;
}

.chairman-yellowbar{
    width: 1rem;
    height: 0.125rem;
    margin-top: 0.125rem;
    margin-bottom: 0.063rem;
    background-color: #ebb700;
}

.chairman-text2{
    font-size: 1.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: center;
    color: #0d2240;
}

.chairman-text3{
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #55565a;
}