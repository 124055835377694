.sponsor-upper-bar{
    width: 23.5rem;
    height: 1.188rem;
    padding: 0.25rem 0rem 0.25rem 0.25rem;
    background-color: #0d2240;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.sponsor-upper-bar-text{
    width: 10rem;
    height: 0.688rem;
    font-size: 0.5rem;
    font-weight: normal;
    color: #ffffff;
}

.sponsor-member-area{
    width: 23.5rem;
    height: 4.063rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.25rem 0rem 0.438rem 1.063rem;
    background-color: #f6f6f6;
}

.sponsor-member-img{
    width: 2.625rem;
    height: 2.625rem;
    border: solid 0px #00338d;
    border-radius: 50%;
}

.sponsor-info-wrap{
    display: flex;
    flex-direction: column;
    width: 7rem;   
    height: 4.063rem;
    margin-left: 0.5rem;
}

.sponsor-addi-info-wrap{
    display: flex;
    flex-direction: column;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    width: 13rem;   
    height: 4.063rem;
    /* margin-left: 0.5rem; */
}

.sponsor-name{
    width: 3.75rem;
    height: 1.5rem;
    margin: 0.15rem 0 0 0;
    font-family: NotoSansCJKkr;
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}

.sponsor-position{
    width: 13.5rem;
    height: 0.688rem;
    margin: 0.1rem 0 0.1rem 0;
    opacity: 0.7;
    font-family: NotoSansCJKkr;
    font-size: 0.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.38);
}

.sponsor-position-thick{
    width: 13.5rem;
    height: 0.688rem;
    margin: 0.1rem 0 0.1rem 0;
    /* opacity: 0.95; */
    font-family: NotoSansCJKkr;
    font-size: 0.55rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.8);
}

.sponsor-mobile{
    width: 5.5rem;
    height: 1rem;
    margin: 0.313rem 0 0 0;
    padding: 0.225rem 0.375rem 0.154rem 0.313rem;
    border-radius: 3px;
    background-color: #757575;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sponsor-spon-content-img{
    max-width: 23.5rem;
    /* max-height: 14.313rem; */
    max-height: 23.5rem;
    margin-top: 0.5rem;
    overflow: auto;
}

.mobile-icon{
    width:0.63rem;
    height: 0.63rem;
}

.img-self1{
    width:100%;
    height:100%;
}

.mobile-text{
    margin: 0 0 0 0.245rem;
    font-family: NotoSansCJKkr;
    font-size: 0.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.clubImg{
    width: 1.124rem;
    height: 1.25rem;
    opacity: 0.74;
}