.login-page{
    font-family: NotoSansCJKkr;
    font-size: 1.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}
 
.mobile-text{
    margin: 0 0 0.034rem 0.245rem;
    font-family: NotoSansCJKkr;
    font-size: 0.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}
