.home-header{
    width: 100%;
    height: 3.5rem;
    padding: 0.813rem 1rem 0.875rem 1.25rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
    background-color: #00338d;
    color:white
}

.home-header-text{
    font-size: 1.25rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.jidae-text{
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.club-text{
    font-size: 1.25rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
}

.slogan-text{
 font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #55565a;  
}

.chairman-name-text{
    font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #55565a;
  margin-left: 0.313rem;
}

.sponsor-btn-text{
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.primemember-title{
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.primemeber-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 19.5rem;
    /* height: 25.688rem; */
    background: #ffffff;
    margin-bottom: 0.6rem;
   
}

.primemeber-img{
    z-index: 5;
    width: 17.5rem;
    height: 17.5rem;
    margin-bottom: 2.5rem;
}

.primemember-wrapper{
    display: flex;
    flex-direction: column;
    background-color: #0d2240;
    width: 20.5rem;
    /* height: 28.688rem; */
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  
}

.primemember-img-wrapper{
    
    position: relative;
}

.footer{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 300;
}

.footer-relative{
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 300;
}

.primember-name-area{
    position:absolute;
    bottom: 0.7rem;
    left:0rem;
    z-index: 104;
    width: 11.375rem;
    height: 3rem;
}

.primember-name-text{
    z-index: 105;
    position:absolute;
    bottom: 1.45rem;
    left:1rem;
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.primemember-slogan-text{
    font-size: 1.0rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    width: 17rem;
}

.primemember-info-text{
    font-size: 0.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #55565a;
    width: 17rem;
}

.primemeber-tel{
    width: 11.7rem;
    height: 1.5rem;
    margin: 0.5rem 0rem 0.5rem 0rem;
    border-radius: 3px;
    background-color: #757575;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding-left: 0.5rem;

}

.primemeber-tel-img{
    width: 1rem;
    height: 1rem;
  
}

.primemeber-tel-text{
    /* font-family: NotoSansCJKkr; */
    font-size: 0.9rem;
    /* font-weight: normal;
    font-stretch: normal;
    font-style: normal; */
    line-height: 1.38;
    /* letter-spacing: normal; */
    text-align: left;
    color: #ffffff;
    margin-left: 0.5rem;
}

.ex-position-text{
    font-size: 0.9rem;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.38);
}

.ex-position-text-overflow{
    font-size: 0.8rem;
    line-height: 1.38;
    letter-spacing: -0.0375em;
    text-align: left;
    color: rgba(0, 0, 0, 0.38);
}

.ex-name-text{
    font-size: 1.25rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
}

.ex-tel{
    width: 7.75rem;
    height: 1.2rem;
    border-radius: 3px;
    background-color: #757575;
    display: flex;
    flex-direction: row;
    margin-top: 0.3rem;
    align-items: center;
}
.ex-tel_extend{
    width: 8rem;
    height: 1.2rem;
    border-radius: 3px;
    background-color: #757575;
    display: flex;
    flex-direction: row;
    margin-top: 0.3rem;
    align-items: center;
}
.ex-tel-img{
    width: 0.63rem;
    height: 0.63rem;
    object-fit: contain;
    margin-left: 0.4rem;
}

.ex-tel-text{
    margin-left: 0.4rem;
    font-size: 0.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.ex-tel2{
    width: 10.3rem;
    height: 2rem;
    border-radius: 3px;
    background-color: #757575;
    display: flex;
    flex-direction: row;
    margin-top: 0.7rem;
    margin-bottom: 1.5rem;
    align-items: center;
}
.ex-tel-img2{
    width: 1rem;
    height: 1rem;
    object-fit: contain;
    margin-left: 0.7rem;
}

.ex-tel-text2{
    margin-left: 0.7rem;
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.ex-wrap{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
}

.ex-wrap-center{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
}

.member-List-club-des{
    position:relative;
    width: 100%;
    /* height: 15.9rem; */
    padding: 0.125rem 2rem 0 2rem;
    background-color: #0d2240;
    display: flex;
    flex-direction: column;
}

.memberlist-club-text{
    font-size: 0.9rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.memberlist-club-text-narrow{
    font-size: 0.7rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -1px;
    text-align: left;
    color: #ffffff;
}

.back-logo-2{
    position:absolute;
    bottom: 5.6rem;
    left:4.453rem;
    width: 13.593rem;
    height: 10.562rem;
    object-fit: contain;
    /* background-image: url('../../images/lionsSymbol206.png'); */
}

.img-money{
    width: 0.9rem;
    height: 0.9rem;
    margin: 0rem 0rem 0rem 0.5rem;
    object-fit: contain;
}

.text-money{
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #00338d;
    margin-left:0.188rem;
}

.sponsor-blank{
    flex:1;
}

.sponsor-wrap{
    width: 2.8rem;
    height: 2.8rem;
    background-color: #00338d;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    border-radius: 0.5rem;
  
}

.sponsor-text{
    font-size: 0.95rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.chairman-upper-bar{
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0d2240;
    margin-left: 1rem;
    margin-right: 1rem;
}

.chairman-upper-text{
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}


.memberDetail-memberInfo{
    position:relative;
    width: 100%;
    /* height: 100%; */
    /* height: 26.5rem; */
    background-color: #0d2240;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;  
}

.picImgHeight{
    height: 116px;
}

.memberDetail-memberInfo-fullheight{
    position:relative;
    width: 100%;
    /* height: 100%; */
    background-color: #0d2240;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;  
}

.memberDetail-back-img{
    position: absolute;
    object-fit: contain;
    opacity: 0.22;
    width: 13.593rem;
    height: 12.875rem;
    z-index: 1;
    top:1rem;
}

.memberDetail-member-img{
    margin-top:3.73rem;
    width:7rem;
    height: 7rem;
    z-index:1;
}

.memberDetail-name-text{
    font-size: 2.2rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-top:3.1rem;

}

.memberDetail-general-info{
    opacity: 0.54;
    font-family: NotoSansCJKkr;
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.memberDetail-general-info-narrow{
    opacity: 0.54;
    font-family: NotoSansCJKkr;
    font-size: 0.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -1px;
    text-align: center;
    color: #ffffff;
}

.table-back-color{
    background-color: #f6f6f6;
    color: #00338d;
}

.table-wrapper{
  margin-top: 0.5rem;  
  margin-left: 0.5rem;  
  margin-bottom: 5.5rem;  
  width: 96.5vw;  
}

.notice-day{
    font-family: NotoSansCJKkr;
    font-size: 0.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.38);
}

.notice-title{
    font-family: NotoSansCJKkr;
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;

    letter-spacing: normal;

    color: rgba(0, 0, 0, 0.87);
}

.fb-notice-title{
    font-family: NotoSansCJKkr;
    font-size: 0.8rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;

    letter-spacing: normal;

    color: rgba(0, 0, 0, 0.87);
}

.notice-content-text{
    font-family: NotoSansCJKkr;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #55565a;
    white-space: pre-line;
} 

.notice-close-pressed{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 3.5rem;
    background-color: #f6f6f6;
}

.notice-close{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 3.5rem;
    background-color: #ffffff;
}

.notice-content-wrapper{
  width: 91%;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 1.063rem 1rem 1rem;
  background-color: #f6f6f6; 
}

.notice-arrow-img{
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
}

.jiyeok-bg-img{
   display: flex;
   background-image: url('../../images/jiyeok4.png'); 
   flex-direction: row;
   align-items: center;
   justify-content: start;
   padding-left: 3.8rem; 
   width: 9rem;
   height: 3.9rem;
   margin-left: 1.2rem;
   margin-right: 1.2rem;
   margin-top: 1rem;
   background-size:contain;
   /* object-fit: fill; */
}

.jiyeok-icon-img{
    background-image: url('../../images/bg_jiyeok.png');
    width:5rem;
    height: 5rem;
    margin-top: 2px;
    margin-bottom: 15px;
    background-size:contain;
}
 
.jiyeok-exe-img{
    display: flex;
    background-image: url('../../images/label.png'); 
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding-left: 0.7rem; 
    width: 156px;
    height: 48px;
    margin-left: 1.2rem;
    margin-right: 1.2rem;
    margin-top: 0.4rem;
    background-size:contain;
}

.select_jigu_notebook{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 60px;
    background-image: url('../../images/jigu_notebook.png');
    width:255px;
    height:138px;
    margin-top: 120px;
    margin-bottom: 100px;
    background-size:contain;
}

.select_club_notebook{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 40px;
    background-image: url('../../images/club_notebook.png');
    width:255px;
    height:138px;
    /* margin-top: 2px; */
    /* margin-bottom: 15px; */
    background-size:contain;
}