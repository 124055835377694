
.clubImg{
    width: 1.5rem;
    height: 1.5rem;
    opacity: 0.74;
}

.clubImg_lions{
    width: 1.54rem;
    height: 1.54rem;
    opacity: 0.74;
}

.back-btn{
    width: 3.5rem;
    height: 3.5rem;
    padding: 1.25rem;
}

.arrow-img{
    width: 1rem;
    height: 1rem;
    object-fit: contain;
}

.header-text{
    font-size: 1.25rem;
    font-weight: 500;
    color:white;
}